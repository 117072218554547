import { css } from '@emotion/react';

export const globalStyles = theme => css`
  body {
    margin: 0;
    font-family: proxima-nova, 'Proxima Nova', 'Helvetica Neue', Helvetica,
      Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${theme.color.contrast};
    color: ${theme.color.primary};
  }

  img {
    height: auto;
    max-width: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }

  .grecaptcha-badge {
    display: none;
  }

  ::selection {
    background: ${theme.color.staticGrey6};
    color: ${theme.color.staticBlack};
  }

  ::-moz-selection {
    background: ${theme.color.staticGrey6};
    color: ${theme.color.staticBlack};
  }

  /** This removes that annoying blue color when auto-filling input -----------------**/
  * Chrome,
  Safari */ input:-webkit-autofill,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  /* Edge (Chromium-based) - similar to Chrome */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
`;
